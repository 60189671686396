<template>
  <div>
    <canvas
      :id="canvasId"
      class="chart-box"
      width="100%"
      height="100%"
    ></canvas>
  </div>
</template>
<script>
import F2 from '@antv/f2'
export default {
  name: 'WeChatCardCount',
  mixins: [],
  components: {},
  props: {
    canvasId: {
      type: String,
      defalut: 'wechatCardCount'
    },
    chartData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      chartRef: []
    }
  },
  computed: {},
  watch: {
  },
  created () { },
  mounted () {
    this.chartRef = new F2.Chart({
      id: this.canvasId,
      pixelRatio: window.devicePixelRatio
    })
    const defs = {
      dt: {
        type: 'timeCat',
        mask: 'MM/DD',
        tickCount: 7,
        range: [0, 1]
      },
      ct: {
        tickCount: 5,
        min: 0,
        alias: '开卡数'
      }
    }
    this.chartRef.source(this.chartData, defs)

    this.chartRef.axis('dt', {
      label: function label (text, index, total) {
        const textCfg = { fill: '#999999' }
        if (index === 0) {
          textCfg.textAlign = 'left'
        } else if (index === total - 1) {
          textCfg.textAlign = 'right'
        }
        return textCfg
      }
    })

    this.chartRef.axis('ct', {
      label: function label (text, index, total) {
        const textCfg = { fill: '#999999' }
        return textCfg
      }
    })
    this.chartRef.tooltip({
      showCrosshairs: true
    })
    this.chartRef.line().position('dt*ct').color('#FF6A3C').shape('smooth')
    this.chartRef.point().position('dt*ct').color('#FF6A3C').shape('smooth')
      .style({
        stroke: '#fff',
        lineWidth: 1
      })
    this.chartRef.render()
  },
  destroyed () { },
  methods: {}
}
</script>
<style lang="scss" scoped>
@import "../../../../src/assets/scss/base/_mixin";
.chart-box {
  width: px2rem(690px);
  height: px2rem(340px);
}
</style>
