<template>
  <page-view>
    <template v-slot:nav>
      <nav-bar
        title="微信会员卡"
        left-arrow
        right-text="推广开卡"
        @click-right="handleRightClick"
      ></nav-bar>
    </template>
    <!-- 主体内容部分 -->
    <div class="bg-gray">
      <div class="summary-box">
        <div class="item">
          <div class="fs-12 flex-algin-center">
            <span>今日会员消费</span>
            <!-- @click="$router.push({ path: '/wx-consumption' })" -->
            <!-- <van-icon name="arrow" /> -->
          </div>
          <div class="mt-5 mb-5">
            <price
              :price="todaySaleMoney || 0"
              :needSymbol="true"
              :thousands="true"
              class="big-price"
            />
            <span class="sale-count"
              >{{ last7DaysData.length && last7DaysData[6].saleNums }}笔</span
            >
          </div>
          <div>
            昨日<price
              :price="yestoadySaleMoney || 0"
              :needSymbol="true"
              :thousands="true"
            />
          </div>
        </div>
        <div class="item">
          <div
            class="fs-12 flex-algin-center"
            @click="$router.push({ path: 'wx-member-list' })"
          >
            <span>今日新增会员</span>
            <van-icon name="arrow" />
          </div>
          <div class="mt-5 mb-5">
            <span class="member">{{ todayMemberCount || 0 }}</span>
            <span class="member-count">个</span>
          </div>
          <div>
            昨日<span class="member-count"
              >{{ yestoadyMemberCount || 0 }}个</span
            >
          </div>
        </div>
      </div>
      <div class="margin-lr-30 nav__box ds-flex">
        <div class="nav__item" @click="handleNavClick('scan')">
          <div class="nav__icon-box icon-primary ds-flex align-center">
            <img
              src="../../../src/assets/images/wechat-card/icon-scan.svg"
              alt=""
            />
            <!-- <i
              class="iconfont2 icon--huiyuanqiajiating nav__icon receive-icon"
            ></i> -->
          </div>
          <div class="nav__title">扫码会员</div>
        </div>
        <div class="nav__item" @click="handleNavClick('getCard')">
          <div class="nav__icon-box icon-orange ds-flex align-center">
            <img
              src="../../../src/assets/images/wechat-card/icon-rc-member.svg"
              alt=""
            />
          </div>
          <div class="nav__title">领卡会员</div>
        </div>
        <div class="nav__item" @click="handleNavClick('ranking')">
          <div class="nav__icon-box icon-warning ds-flex align-center">
            <img
              src="../../../src/assets/images/wechat-card/icon-recoment.svg"
              alt=""
            />
            <!-- <i class="iconfont2 icon-icon-test nav__icon ranking-icon"></i> -->
          </div>
          <div class="nav__title">推荐排名</div>
        </div>
        <div class="nav__item" @click="handleNavClick('setting')">
          <div class="nav__icon-box icon-success ds-flex align-center">
            <img
              src="../../../src/assets/images/wechat-card/setting-icon.svg"
              alt=""
            />
            <!-- <i class="iconfont2 icon-meihua desgin-icon nav__icon"></i> -->
          </div>
          <div class="nav__title">会员卡设置</div>
        </div>
      </div>

      <!-- 图表 -->
      <div class="margin-lr-30 chart-box mt--20">
        <div class="chart__title">近7日会员新增开卡趋势</div>

        <div v-if="last7CountLoading" class="load__error">
          <van-loading>获取数据中</van-loading>
        </div>
        <chart-count
          canvasId="1wechatCardChar"
          v-else
          :chartData="last7Count"
        />
        <div
          v-if="last7DayError"
          @click="handleReloadChart"
          class="load__error"
        >
          加载超时，点击请重试！
        </div>
      </div>
      <div class="margin-lr-30 chart-box">
        <div class="chart__title">近7日开卡会员订单消费趋势</div>

        <div v-if="last7DayLoading" class="load__error">
          <van-loading>获取数据中</van-loading>
        </div>
        <chart canvasId="wechatCardChart" v-else :chartData="last7DaysData" />
        <div
          v-if="last7DayError"
          @click="handleReloadChart"
          class="load__error"
        >
          加载超时，点击请重试！
        </div>
      </div>
      <van-dialog v-model="showDialog">
        <img
          src="../../../src/assets/images/wechat-card/showSetting.png"
          height="200"
        />
        <h4 class="text-center mt-5 mb-5">请登录生意专家电脑端进行设置</h4>
        <p class="text-center mb-5">www.shengyi.ai</p>
      </van-dialog>

      <div v-show="sharedialog" class="share__box" @click="sharedialog = false">
        <div class="share-content">
          <canvas id="posterHtml"></canvas>
          <img :src="posterQr" crossorigin="anonymous" />
        </div>
        <div class="share-bottom">
          <div class="border-box van-hairline--bottom">
            <span class="share-title"> 扫码成为本店会员 </span>
          </div>
          <div class="share__btn">
            <div @click="shareToWechat">
              <i class="iconfont2 icon-weixin wechat-icon"></i>
              <span>微 信</span>
            </div>
            <div @click="shareToWechatQ">
              <i class="wechatq-icon"></i>
              <span class="pengyouquan">朋友圈</span>
            </div>
            <div @click="saveImg">
              <i class="iconfont2 icon-tupian saveimg-icon"></i>
              <span>保存图片</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <template v-slot:footer style="z-index: 0">
      <div class="bottom__btn">
        <van-button
          color="#17BF62"
          @click="save"
          class="save__btn"
        >保存
        </van-button>
        <van-button
          color="#FF6A3C"
          @click="share"
          class="share__btn"
        >发布
        </van-button>
      </div>
    </template> -->
  </page-view>
</template>
<script>
import { mapState } from 'vuex'
import miniProgramModel from '../../model/miniprogram/index'
// import posterImg from '../../assets/images/wechat-card/poster-bgc.png'
import bankImg from '../../assets/images/wechat-card/test-bank.png'
import { downloadPic } from '../../utils/wxcard-canvas.js'
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import { Divider, Loading, Image, Icon, Dialog, Button } from 'vant'
import Chart from './modules/chart'
import ChartCount from './modules/chartCount'
import WechatCardModel from '@/model/wechat-card/index'
import dayjs from 'dayjs'
import Price from '@/components/price'
import axios from 'axios'
import { imgFullFix } from '@/utils/img'
const posterImg = 'http://img.laoban100.com/WeChatCardTicket/poster-bgc.png'
export default {
  name: 'WechatCard',
  mixins: [],
  components: {
    ChartCount,
    [Button.name]: Button,
    Price,
    [Dialog.Component.name]: Dialog.Component,
    [Icon.name]: Icon,
    PageView,
    Chart,
    [Divider.name]: Divider,
    [Loading.name]: Loading,
    [Image.name]: Image,
    NavBar
  },
  props: {},
  data () {
    return {
      saveImgLoading: false,
      saveImgURL: '',
      uploadOption: {}, // 上传图片设置
      canvas: null,
      posterQr: '',
      cardDetail: null,
      last7Count: [],
      last7CountLoading: false,
      sharedialog: false,
      showDialog: false,
      showTip: false,
      loading: false, // 加载中
      loaded: false, // 加载完成
      totalMoney: null,
      empty: false,
      isFinished: false,
      totalUserNum: 0,
      memberList: [],
      last7DayLoading: false,
      last7DayError: false,
      pageConfig: {
        pageIndex: 0,
        pageSize: 5,
        totalCount: 0
      },
      last7DaysData: [],
      last2DaysData: [],
      imgList: [{ url: 'https://img.laoban100.com/barcode/690/1028/6901028045902!small' }, { url: 'https://img.laoban100.com/goodsImg/2020/04/09/399113/83916f62312d2ba6fd931b10f89f9bd1.jpg!small' }, { url: 'https://img.laoban100.com/storelogo/2020/399113/bc6769b57b1f65f64fe50db76cf33343.png!small' }]
    }
  },
  computed: {
    ...mapState({
      accountId: state => state.userInfo.accId,
    }),
    todaySaleMoney () {
      const target = this.last7DaysData.find(item => {
        const currentDay = this._getCurrentDate()
        return currentDay.isSame(item.currentDate)
      })

      return target ? target.amount : null
    },
    yestoadySaleMoney () {
      const target = this.last7DaysData.find(item => {
        const currentDay = this._getCurrentDate()
        return currentDay.subtract(1, 'day').isSame(item.currentDate)
      })
      return target ? target.amount : null
    },
    // 今日新增会员数
    todayMemberCount () {
      const target = this.last2DaysData.find(item => {
        const currentDay = this._getCurrentDate()
        return currentDay.isSame(item.currentDate)
      })
      return target ? target.userCount : null
    },
    // 昨日新增会员
    yestoadyMemberCount () {
      const target = this.last2DaysData.find(item => {
        const currentDay = this._getCurrentDate()
        return currentDay.subtract(1, 'day').isSame(item.currentDate)
      })
      return target ? target.userCount : null
    }
  },
  watch: {},
  created () {
  },
  mounted () {
    this.initData()
  },
  methods: {
    dayjs,
    imgFullFix,
    // 获取签名
    blobToFile (theBlob, fileName) {
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return theBlob
    },
    async getSignature () {
      // canvas图片base64 转 File 对象
      const dataURL = this.canvas.toDataURL('image/png')
      const arr = dataURL.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      let obj = new Blob([u8arr], { type: mime })
      obj = this.blobToFile(obj, 'a.png')
      const formData = new FormData()
      formData.append('file', obj)
      // 获取文件后缀
      const suffix = formData.get('file').type.split('/')[1]
      const data = await miniProgramModel.getSign({ suffix: suffix })
      if (data.code === 0) {
        this.uploadOption.path = data.data.uploadUrl
        formData.append('policy', data.data.policy)
        formData.append('signature', data.data.signStr)
        formData.append('bucketName', 'img-i200')
        this.updateImg(formData)
      }
    },
    // 上传
    updateImg (formData) {
      axios({
        url: 'https://v1.api.upyun.com/img-i200/',
        method: 'POST',
        data: formData,
        processData: false,
        contentType: false
      }).then(res => {
        if (res.data.code === 200) {
          const url = res.data.url
          this.saveImgURL = 'http://img.laoban100.com' + url
          this.saveImgLoading = false
          this.sharedialog = true
        }
      })
    },
    async createPoster () { // 生成canvas海报
      const canvas = document.getElementById('posterHtml')
      const ctx = canvas.getContext('2d')
      downloadPic(canvas, ctx, posterImg, this.cardDetail, this.posterQr)
      setTimeout(() => {
        this.canvas = canvas
        this.getSignature()
      }, 500)
    },
    shareToWechat () { // 分享至微信
      // if (this.saveImgLoading && this.saveImgURL) return
      window.$fe_bridge.shareAction.wechatSharePicture({
        imgUrl: this.saveImgURL
      })
    },
    shareToWechatQ () { // 分享至微信朋友圈
      // if (this.saveImgLoading && this.saveImgURL) return
      window.$fe_bridge.shareAction.circleFriendsSharePicture({
        imgUrl: this.saveImgURL
      })
    },
    saveImg () { // 保存图片
      // if (this.saveImgLoading && this.saveImgURL) return
      window.$fe_bridge.shareAction.savePicture({
        title: '',
        link: '',
        imgUrl: this.saveImgURL,
        description: ''
      }, () => {
      })
    },
    async initData () {
      // const totalSaleMoney = await WechatCardModel.getTotalSaleMoney()
      // const totalMemberCount = await WechatCardModel.getTotalMemberNum()
      this.last7CountLoading = true
      this.last7Count = await WechatCardModel.getLast7DayCount()
      this.last7CountLoading = false
      await WechatCardModel.getTotalMemberNum()
      try {
        this.last7DayLoading = true
        const last7DayJson = await WechatCardModel.getLast7DaysList()
        this.last7DayLoading = false
        if (last7DayJson.result) {
          this.last7DaysData = last7DayJson.data
        }
      } catch (error) {
        this.last7DayError = true
      }
      const last2DaysMemberJson = await WechatCardModel.getLasy2DaysMemberList()
      this.last2DaysData = last2DaysMemberJson
      // this.totalMoney = totalSaleMoney
      // this.totalUserNum = totalMemberCount
      // this.loadMembers(true)
      WechatCardModel.getCardDetail().then(res => {
        this.cardDetail = res.data
      })
      WechatCardModel.getPosterQr(this.accountId).then(res => {
        this.posterQr = res.data.qrcodeUrl
      })
    },
    // 重新加载数据
    async handleReloadChart () {
      try {
        this.last7DayLoading = true
        this.last7DayError = false
        const last7DayJson = await WechatCardModel.getLast7DaysList()
        this.last7DayLoading = false
        if (last7DayJson.result) {
          this.last7DaysData = last7DayJson.data
        }
      } catch (error) {
        this.last7DayError = true
        this.last7DayLoading = false
      }
    },
    // 导航提示
    handleNavClick (type) {
      if (type === 'getCard') {
        if (!window.$bridge) return
        window.$bridge.page.memberList("wechatCardMember"); // eslint-disable-line
      } else if (type === 'rule') {
        if (!window.$bridge) return
        window.$bridge.page.wechatCardRewardSetting(); // eslint-disable-line
      } else if (type === 'setting') {
        this.showDialog = true
        // if (!window.$bridge) return
        // window.$bridge.page.wechatCardSetting(); // eslint-disable-line
      } else if (type === 'ranking') {
        // 推荐排名
        this.$router.push('/recommend/index')
      } else if (type === 'scan') {
        if (!window.$bridge) return
        window.$bridge.page.wechatScanMember(); // eslint-disable-line
      }
    },
    // 加载更多数据

    async loadMembers (isNewPage = false) {
      if (isNewPage) {
        this.memberList = []
        this.pageConfig.pageIndex = 0
        this.pageConfig.totalCount = 0
        this.isFinished = false
        this.empty = false
      }
      const start = this.pageConfig.pageIndex * this.pageConfig.pageSize
      const limit = this.pageConfig.pageSize
      const { result, data } = await WechatCardModel.getRecommandMembersByPage(
        start,
        limit
      )
      if (result) {
        this.memberList = this.memberList.concat(data.items)
        this.pageConfig.pageIndex += 1
        this.pageConfig.totalCount = data.totalCount
        this.isFinished = data.items.length < this.pageConfig.pageSize
        this.empty = this.pageConfig.pageIndex === 0 && !data.items.length
      } else {
        throw Error('加载推荐用户列表失败')
      }
    },
    // 导航右侧点击事件
    handleRightClick () {
      this.createPoster()
    },

    _getCurrentDate () {
      const currentDay = dayjs()
        .set('second', 0)
        .set('hour', 0)
        .set('minute', 0)
        .set('millisecond', 0)
      return currentDay
    }
  }
}
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
